.red-background {
    --background: red;
    --background-activated: darkred;  /* Color cuando se presiona el botón */
}

.partial-color-background {
    --background: #5bc0de;
    --background-activated: #46b8da;  /* Color cuando se presiona el botón */
}

ion-searchbar.custom {
    --color: #fff;
    --placeholder-color: #fff;
    --icon-color: #fff;
    --clear-button-color: #fff;
}

ion-select {
  --placeholder-color: #fff;
  --placeholder-opacity: 1;
  width: 100%;
  justify-content: center;
  margin-top: 8px;
  padding-top: 12px;  
  padding-right: 9px;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);  
}

ion-select::part(placeholder),
ion-select::part(text) {
  flex: 0 0 auto;
}

ion-select::part(text) {
  color: #fff;
}

ion-select::part(icon) {
  color: #fff;
  opacity: 1;
}