.unobike-theme {
    --ion-background-color: var(--unobike-color-dark-grey);
    --background: var(--unobike-color-dark-grey);
    --internal-light-dark: var(--unobike-color-dark-grey);
    --ion-text-color: var(--unobike-color-light-grey) !important;
    --ion-color-primary: var(--unobike-color-red);
    --ion-color-danger: var(--unobike-color-red);
    --ion-item-background: var(--unobike-color-dark-grey) !important;
    --ion-toolbar-background: var(--unobike-color-dark-grey) !important;
    --ion-tab-bar-background: var(--unobike-color-dark-grey) !important;
    --ion-card-background: var(--unobike-color-dark-grey) !important;
}

.back-try {
    background-color: var(--unobike-color-dark-grey);
}

.unobike-background {
    background-color: var(--unobike-color-dark-grey) !important;
}

.unobike-text {
    color: var(--unobike-color-light-grey) !important;
}

.unobike-item-border {
    --border-width: 0.1rem !important;
    --border-radius: 5px;
    --inner-padding-end: 0px;
}

.unobike-item-warning {
    --border-width: 0.1rem !important;
    --border-radius: 5px;
    --border-color: rgb(224, 187, 46);
    --inner-padding-end: 0px;
}

.unobike-item-success {
    --border-width: 0.1rem !important;
    --border-radius: 5px;
    --border-color: rgb(41, 196, 103);
    --inner-padding-end: 0px;
}

.unobike-item-storage-picker {
    --border-color: var(--unobike-color-red);
    --border-width: 0.1rem !important;
    --border-radius: 5px;
    --inner-padding-end: 0px;
}

.unobike-item-storage-picker-text {
    width: 100%;
    text-align: center;
    color: var(--unobike-color-red);
}

.ion-color-unobike-red {
    --ion-color-base: var(--unobike-color-red);
    --ion-color-base-rgb: var(--unobike-color-red-rgb);
    --ion-color-contrast: var(--unobike-color-red-contrast);
    --ion-color-contrast-rgb: var(--unobike-color-red-contrast-rgb);
    --ion-color-shade: var(--unobike-color-red-shade);
    --ion-color-tint: var(--unobike-color-red-tint);
}

.icon-login {
    width: 100%;
    height: 50px;
    color: var(--unobike-color-light-grey);
}

.icon-text {
    color: var(--unobike-color-light-grey) !important;
}

.inventory-number-font-bg {
    font-size: 1rem;
}

.inventory-number-font-xs {
    font-size: 0.7rem;
}

.button-barcode {
    height: 90%;
}

.boxListItems {
    border: 1px solid var(--unobike-color-light-grey);
    border-radius: 5px;
}

.shadow-box {
    box-shadow: 0 0 5px 0 rgb(35, 35, 35);
    background-color: var(--unobike-color-dark-grey);
}

.unobike-border-success {
    border: 1px solid rgb(41, 196, 103);
}

.unobike-border-warning {
    border: 1px solid rgb(224, 187, 46);
}

.unobike-arrow-location {
    display: flex;
    align-items: center;
    justify-content: center;
}

.unobike-height-screen {
    overflow: auto;
    height: 87vh;
}

.logout-button .action-sheet-icon {
    color: var(--unobike-color-red);
}

.logout-button span {
    color: var(--unobike-color-red);
}

.action-sheet-container .action-sheet-title {
    color: var(--unobike-color-light-grey);
}

.action-sheet-container .action-sheet-cancel {
    color: var(--unobike-color-light-grey);
}

.item .sc-ion-label-md-h {
    white-space: inherit;
}

.item, .item-divider {
    border-color: var(--unobike-color-dark-grey);
    margin: 0;
    padding: 0 3px 3px 3px;
}

.item-search {
    width: 100%;
}

.variant-text-list {
    color:rgb(90, 90, 90)
}

.label-list {
    margin-top: 0.3rem;
    padding: 0;
}

.label-list ion-label {
    margin: 0;
    padding: 0;
}

.label-list ion-item::part(native) {
    padding: 0;
}

.label-list ion-item::part(native) .item-inner {
    padding: 0;
}

.label-list ion-avatar {
    margin: 0 3px!important;
}

.label-list ion-grid {
    padding: 0;
}

.buttons-received ion-row {
    padding: 0;
}

.buttons-received ion-col {
    padding: 0;
}

.buttons-received ion-button {
    padding: 0;
    margin: 0;
    width: 100%;
}

.scan {
    transition: height 0.25s;
    -moz-transition: height 0.25s;
    -webkit-transition: height 0.25s;
    --background: transparent !important;
    --ion-background-color: transparent !important;
}

.scan.off {
    display: none;
    height: 0px;
}

.scan.on {
    display: block;
    height: 350px;
}

.chip-unobike {
    --background:rgb(224, 64, 85, 0.12);
    --color: rgb(224, 64, 85, 0.87);
    border-radius: 16px;
    margin: 4px;
    padding: 7px 12px;
    display: inline-flex;
    position: relative;
    align-items: center;
    height: 32px;
    background: var(--background);
    color: var(--color);
    font-family: var(--ion-font-family,);
    font-size: 14px;
    line-height: 1;
    cursor: pointer;
    overflow: hidden;
    vertical-align: middle;
    box-sizing: border-box;
    text-align: center;
}

.chip-unobike.has-focus {
    border: 1px solid;
}

.input-focus {
    width: 100%;
    --padding-top: 0;
    --padding-start: 0.9em;
    --padding-end: 0.9em;
    --padding-bottom: 0;
    height: 2.1em;
    font-size: 13px;
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    text-align: center;
}

.input-focus.has-focus {
    background-color: rgb(191, 4, 17, 0.12);
    border-color: rgb(191, 4, 17, 0.87);
    color: rgb(191, 4, 17, 0.87);
}

.unobike-button-margin {
    margin: 0 5px;
}