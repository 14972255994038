ion-thumbnail {
    --size: 140px;
    --border-radius: 14px;
}

/* Estilo para el IonFabButton */
.custom-fab-button {
    background-color: #007bff; /* Color de fondo del botón */    
    width: 48px; /* Ancho del botón */
    height: 48px; /* Altura del botón */
  }
  
  /* Estilo para el subíndice en forma de bolita */
  .subscript-dot {
    width: 16px; /* Ancho de la bolita */
    height: 16px; /* Altura de la bolita */
    color: #f00;
    background-color: #fff; /* Color de la bolita */
    border-radius: 50%; /* Forma redonda */
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

.alert-radio-label {
  color: #fff !important;
}