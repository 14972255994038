.searchbar-search-icon.sc-ion-searchbar-md {
    color: #fff;
}

.searchbar-input.sc-ion-searchbar-md {
    color: #fff;
}

.searchbar-clear-button.sc-ion-searchbar-md {
    color: #fff;
}