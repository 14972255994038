ion-modal#modalReturn {
    --height: 48%;
    --width: 95%;
    --border-radius: 10px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }
  
  ion-modal#modalReturn::part(backdrop) {
    background: rgb(0, 0, 0);
    opacity: 0.5;
  }
  
  ion-modal#modalReturn ion-toolbar {
    --background: #ccc;
    --color: #333;
  }